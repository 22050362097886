<template>
  <div style="width: 1200px; margin: 0 auto">
    <div style="width: 100%; height: 75px; display: flex; align-items: center">
      <div
        style="width: 1200px; border-bottom: 1px solid #fc7c40; height: 40px"
      >
        <div class="tixing" style="width: auto;padding-right: 30px">{{ data.name }}</div>
        <span
          style="float: right; display: inline-block; cursor: pointer"
          @click="goExpertMore"
          >更多>></span
        >
      </div>
    </div>
    <div class="rmzl_content" style="height:auto">
      <div class="rmzl_content_header" style="height: auto">
        <div style="font-size: 16px" class="zl_classify">
          <span
            @click="setExpertLabel(index)"
            style="cursor: pointer"
            v-for="(item, index) in data && data.labels"
            :key="index"
            :style="[
              { color: selectExpertId === index ? 'rgba(255, 106, 0, 1)' : '' },
            ]"
            >{{ item.name }}</span
          >
        </div>
      </div>
      <div class="ktzjtj" style="justify-content: flex-start;padding-left: 0;padding-right: 0;padding-bottom:0;border: none;height: auto">
        <div
          class="rwjs"
          style="margin-left: 15px;margin-bottom: 20px"
          @click="goExpertDetail(item.id)"
          v-for="item in data &&
          data.labels.length > 0 && data.labels[selectExpertId].objs"
          :key="item.id"
        >
          <div class="rwtx">
            <div style="width: 80px; margin: 40px auto">
              <Avatar
                icon="ios-person"
                size="70"
                style="margin: 0 auto"
                :src="item.pic"
              />
            </div>
          </div>

          <div
            class="rwjs_sub"
            style="
              float: left;
              height: 280px;
              width: 423px;
              box-sizing: border-box;
              padding-top: 40px;
              padding-bottom: 20px;
              padding-right: 20px;
            "
          >
            <div class="name_uni">{{ item.name }} <span style="display: inline-block;width: 2px;height: 16px;background-color: black"></span> {{ item.pa }}</div>
            <div class="subject">计算机科学与技术</div>
            <div style="text-align: left">
              <span style="color: #FDCE63">//////</span>
            </div>
            <div class="intro_detail ellipse-6" style="line-height: 26px; -webkit-line-clamp: 5;">
              {{ item.introduction  || '暂无简介'}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "expertRecommend",

  props: {
    data: Object,
  },
  watch: {
    data: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.selectExpertId = 0;
        }
      },
    },
  },
  data() {
    return {
      selectExpertId: -1,
    };
  },
  methods: {
    setExpertLabel(id) {
      this.selectExpertId = id;
    },
    goExpertMore() {
      this.$router.push({
        path: "storeList",
        query: {
          tab: 1,
        },
      });
    },
    goExpertDetail(id) {
      this.$router.push({
        path: "experts",
        query: {
          shopId: id,
        },
      });
    },
  },
};
</script>

<style>
.ktzjtj {
  max-width: 1200px;
  height: 605px;
  box-sizing: border-box;
  padding: 15px;
  border: 1px solid #f5f5f5;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.rwjs {
  width: 575px;
  height: 280px;
  border: 1px solid #f5f5f5;
  flex-shrink: 0;
  cursor: pointer;
}

.rwjs_sub div {
  margin-bottom: 10px;
}
.ivu-avatar>img {
  height: 100%!important;
}
.rwtx {
  width: 148px;
  float: left;
  height: 100%;
}

.name_uni {
  font-size: 20px;
  font-family: "Microsoft YaHei", serif;
  color: rgb(51, 51, 51);
  font-weight: bold;
}

.subject {
  font-size: 14px;
  font-family: "Microsoft YaHei", serif;
  color: rgb(51, 51, 51);
}

.zl_classify span {
  margin-right: 20px;
  line-height: 30px;
}

.intro_detail {
  font-size: 14px;
  font-family: "Microsoft YaHei", serif;
  color: rgb(51, 51, 51);
}

.ktzjtj > div:hover {
  box-shadow: 6px 0px 15px 0px rgba(127, 127, 127, 0.1);
}
</style>
